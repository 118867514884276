import React from 'react'

const InputForm = ({
  id,
  value,
  onChange,
  disabled,
  type,
  isEdited,
  name = '',
  label = '',
  colSpan = 'col-span-1',
  size = 'w-5/6',
  checked,
  properties
}) => (
  <label htmlFor={id} className={`flex flex-col ${colSpan}`}>
    {label}
    <input
      id={id}
      type={type}
      className={`my-2 ${size} border border-medium-gray  ${
        isEdited ? ' opacity-70' : ' hover:opacity-70'
      }`}
      value={value}
      onChange={onChange}
      disabled={disabled}
      name={name}
      checked={checked}
      {...properties}
    />
  </label>
)

export default InputForm
