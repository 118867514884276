import React from 'react'
import SearchBar from '../shared/SearchBar'
import FormButton from '../shared/FormButton'

const SearchAndEditControls = ({
  searchBarInput,
  searchHandler,
  isEdited,
  editPackages,
  cancelEdit,
  loading
}) => (
  <div className="mx-10 flex items-center justify-end gap-4">
    <SearchBar
      searchbarInput={searchBarInput}
      searchKeyword={searchHandler}
      setSearchbarInput={(search) => searchHandler(search)}
    />
    <FormButton
      text={!isEdited ? 'Editar' : 'Guardar cambios'}
      onClick={editPackages}
      type="button"
      styles="py-2 px-2 text-base"
      disabled={loading}
    />
    <FormButton
      isEditing={!isEdited}
      text="Cancelar"
      onClick={cancelEdit}
      type="button"
      styles="py-2 px-2 text-base"
      disabled={!isEdited}
    />
  </div>
)

export default SearchAndEditControls
