import React, { useState } from 'react'
import Select from '../shared/Input/Select'
import { MONTHS, MONTHS_HASH, YEARS } from '../../constants/others'
import FormButton from '../shared/FormButton'
import { parseFirstDay, parseLastDay } from '../../utils/dates'
import { fetchDataInBatches } from '../../helpers/processBilling/OrdersBilling'
import Loader from '../shared/Loader'
import { BILLING_ICONS, errorIcon } from '../../constants/icons'

const FetchDataModal = () => {
    const [selectedMonth, setSelectedMonth] = useState('Ene')
    const [selectedYear, setSelectedYear] = useState('2024')
    const [error, setError] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [isActive, setIsActive] = useState(false)

    const transferData = async () => {
        const monthValue = MONTHS_HASH[selectedMonth]
        const startDate = parseFirstDay(`${selectedYear}-${monthValue}-01`)

        const endDate = monthValue === 12
            ? parseLastDay(`${selectedYear}-12-31`)
            : parseLastDay(`${selectedYear}-${monthValue + 1}-01`)
            setisLoading(true)
            setIsActive(true)
        try {
            const response = await fetchDataInBatches( startDate, endDate )
            if(response.statusCode > 300 || response.statusCode < 200) setError(true)
            else setError(false)

        } catch (e) {
            setError(true)
        }
        setisLoading(false)
    }

    const responseStatus = () => {
        if(error){
            return <img src={BILLING_ICONS.paid} className="mx-auto h-16 w-16" alt="success" />
        }
        return <img src={errorIcon} className="mx-auto h-16 w-16" alt="error" />
    }

    return (
        <div className="p-4">
            <div>
                Elegir mes para buscar paquetes
            </div>
            {isActive && <div>
            { isLoading? <Loader /> : responseStatus() }
            </div>}
            <div className='flex'>
                <Select
                    options={MONTHS}
                    label="Mes"
                    setter={setSelectedMonth}
                    withName
                    defaultValue={MONTHS[0].name}
                />
                <Select
                    options={YEARS}
                    label="Año"
                    setter={setSelectedYear}
                    withName
                    defaultValue={YEARS[2].name}
                />
                <FormButton
                    text="Buscar"
                    type="button"
                    styles="mt-4 py-2 px-2 text-base"
                    onClick={() => { transferData() }}
                />
            </div>
        </div>
    )


}
export default FetchDataModal
