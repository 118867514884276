import React, { useCallback, useEffect, useState } from 'react'

import ActionsBar from '../components/shared/Table/ActionsBar'
import {
  createBills,
  getBilledPackages,
  getBills,
  savePackages,
  updatePackages
} from '../helpers/requests/billing'
import { handleBillingsDownload, parseJsonOrders } from '../helpers/processBilling/OrdersBilling'
import { PACKAGE_BILL_STATUS, PACKAGE_BILL_STATUS_INDEX } from '../constants/billing'
import { isSubstring } from '../utils/strings'
import useFetch from '../hooks/useFetch'
import { updateElementArrayOfObjects } from '../utils/array'
import { parseWorkbook, readFile } from '../utils/files'
import ModalHandler from '../components/Billing/ModalHandler'
import FileUploadSection from '../components/Billing/FileUploadSection'
import SearchAndEditControls from '../components/Billing/SearchAndEditControls'
import TableSection from '../components/Billing/TableSection'
import ConfirmModal from '../components/shared/Modal/ConfirmModal'

const Billing = () => {
  const [file, setFile] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [isResponse, setIsResponse] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [courier, setCourier] = useState('')
  const [loading, setLoading] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [processedFile, setProcessedFile] = useState([])
  const [searchBarInput, setSearchBarInput] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [editedPackages, setEditedPackages] = useState([])
  const [dataBackUp, setDataBackUp] = useState([])
  const [editDataBackup, setEditDataBackup] = useState([])
  const [selectedFilters, setSelectedFilters] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [shouldFetch, setShouldFetch] = useState(false)
  const [billAlert, setBillAlert] = useState(false)

  const initialzeData = useCallback((data) => {
    setSearchResult(data)
    setDataBackUp(data)
  }, [])

  const { isLoading } = useFetch(getBilledPackages, initialzeData, '')

  const setModalData = (data) => {
    setShowModal(true)
    setIsResponse(true)
    setModalMessage(data)
  }

  const saveData = async () => {
    setLoading(true)
    if (!file) {
      setLoading(false)
      return
    }
    try {
      const data = await readFile(file)
      const workbook = parseWorkbook(data)
      const parsedData = parseJsonOrders(courier, workbook)
      const savedPackagesResponse = await savePackages({ sales: parsedData, courier })
      setModalData(savedPackagesResponse)
      const res = await getBilledPackages()
      setProcessedFile(res)
      setSearchResult(res)
    } catch (e) {
      setFile(null)
    }
    setLoading(false)
  }

  const resetData = () => {
    setEditedPackages([])
    setIsEdited(false)
    setShouldFetch(true)
    setSearchBarInput('')
  }

 useEffect(() => {
  if (shouldFetch) {
    getBilledPackages('')
      .then(initialzeData)
      .finally(() => setShouldFetch(false))
  }
}, [shouldFetch, initialzeData])

  const editPackages = async () => {
    if (isEdited) {
      const result = await updatePackages({ editedPackages })
      setModalData(result)
      resetData()
      setIsEdited(false)
    } else {
      setIsEdited(true)
      setEditDataBackup([...searchResult])
    }
  }

  const cancelEdit = () => {
    resetData()
    setSearchResult([...editDataBackup])
  }

  const searchBarFilter = (resultsList, searchTerm) => {
    if (searchTerm !== '') {
      const filteredBills = searchResult.filter(
        (billing) =>
          isSubstring(billing.tracking_number, searchTerm) ||
          (billing.courier && isSubstring(billing.courier, searchTerm)) ||
          (billing.order_id && isSubstring(billing.order_id, searchTerm)) ||
          (billing.company && isSubstring(billing.company, searchTerm)) ||
          (billing.city_name && isSubstring(billing.city_name.toString(), searchTerm))
      )
      setSearchResult(filteredBills)
    } else {
      setSearchResult(resultsList)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    searchBarFilter(dataBackUp, searchTerm)
  }

  const handleCheckboxChange = (item, field) => {
    const newEditedPackages = updateElementArrayOfObjects(
      editedPackages,
      { ...item, [field]: !item[field] },
      'id'
    )
    setEditedPackages(newEditedPackages)
    const updatedProcessedFile = searchResult.map((sale) => {
      if (sale.id === item.id) {
        return { ...sale, [field]: !sale[field] }
      }
      return sale
    })
    setSearchResult(updatedProcessedFile)
  }

  const handleCloseModal = () => {
    if (showModal) setShowModal(false)
    else if (isResponse) setIsResponse(false)
  }

  const handleConfirmCreateBills = ()=>{
    setBillAlert(true)
  }

  const handleCreateBills = async () => {
    setBillAlert(false)
    setLoading(true)
    setShowModal(true)
    setIsResponse(true)
    try {
      const response = await createBills()
      setModalData(response)
    } catch (e) {
      setModalData({ status: 500, body: `Error al crear Facturas.\n${e}` })
    }
    setBillAlert(false)
    setLoading(false)
  }

  const handleGetBillData = async () => {
    setLoading(true)
    try {
      const response = await getBills('')
      const message = handleBillingsDownload(response)
      setModalData(message)
    } catch (e) {
      setModalData({ statusCode: 500, body: `Error al traer Facturas.\n${e}` })
    }
    setLoading(false)
  }

  const stateFilteredBills =
    selectedFilters.length > 0
      ? searchResult.filter((bill) => {
        const isApproved = {}
        bill.approved_by_price && bill.approved_by_weight
          ? (isApproved.value = 'aprobado')
          : (isApproved.value = 'rechazado')
        return selectedFilters.includes(PACKAGE_BILL_STATUS_INDEX[isApproved.value])
      })
      : searchResult

  const currentBills = stateFilteredBills?.slice(pageSize * (page - 1), pageSize * page) || []

  return (
    <div className="relative flex h-screen flex-col bg-light-grey">
      <div className="mx-10 my-5">
        <div className="mb-8 text-left text-xl font-semibold">Facturas de Courier</div>
        <ConfirmModal
          show={billAlert}
          handleSubmit={handleCreateBills}
          handleClose={() => setBillAlert(false)}
          isLoading={loading}
          message="Esta acción sobrescribirá las facturas ya creadas. ¿Desea continuar?"
        />
        <ModalHandler
          showModal={showModal}
          isResponse={isResponse}
          modalMessage={modalMessage}
          loading={loading}
          handleCloseModal={handleCloseModal}
        />
        <div className='flex items-end justify-between'>
          <FileUploadSection
            courier={courier}
            setCourier={setCourier}
            file={file}
            setFile={setFile}
            saveData={saveData}
            loading={loading}
            handleCreateBills={handleConfirmCreateBills}
            handleGetBillData={handleGetBillData}
            setShowModal={setShowModal}
            setIsResponse={setIsResponse}
          />
          <SearchAndEditControls
            searchBarInput={searchBarInput}
            searchHandler={searchHandler}
            isEdited={isEdited}
            editPackages={editPackages}
            cancelEdit={cancelEdit}
            loading={loading}
          />
        </div>
        <ActionsBar
          statusFilters={PACKAGE_BILL_STATUS}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
        <TableSection
          headers={[
            'Courier',
            'Company',
            'OV',
            'OT',
            'Comuna',
            'Precio envío',
            'Peso declarado',
            'Peso cobrado',
            'Cobrado',
            'BasePrice',
            'CourierPrice',
            'Aprobado por Peso',
            'Aprobado por Precio',
            'Pagado',
            'Activo'
          ]}
          processedFile={processedFile}
          isLoading={isLoading}
          currentBills={currentBills}
          handleCheckboxChange={handleCheckboxChange}
          isEdited={isEdited}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          itemsCount={stateFilteredBills.length}
        />
      </div>
    </div>
  )
}

export default Billing
