import React from 'react'
import Table from '../shared/Table/Table'
import TableRow from '../shared/Table/TableRow'
import InputForm from '../shared/Input/InputForm'
import Pagination from '../shared/Pagination'


const TableSection = ({
  headers,
  isLoading,
  currentBills,
  handleCheckboxChange,
  isEdited,
  page,
  setPage,
  pageSize,
  setPageSize,
  itemsCount
}) => (
  <>
    <Table headers={headers} roundedTop>
      {!isLoading && currentBills.map((item) => (
        <TableRow
          key={item.id}
          onClick={() => { }}
          items={[
            <div className="flex w-32 items-center">{item.courier || '-'}</div>,
            <div className="flex w-32 items-center">{item.company_name || '-'}</div>,
            <div className="flex w-32 items-center">{item.order_id}</div>,
            <div className="flex w-32 items-center">{item.tracking_number || '-'}</div>,
            <div className="flex w-32 items-center">{item.city_name || '-'}</div>,
            <div className="flex w-32 items-center">{item?.shipping_paid || '0'}</div>,
            <div className="flex w-32 items-center">{item.declared_weight || '-'}</div>,
            <div className="flex w-32 items-center">{item.weight_billed || '-'}</div>,
            <div className="flex w-32 items-center">{item.billed || '-'}</div>,
            <div className="flex w-32 items-center">{item.base_price || '-'}</div>,
            <div className="flex w-32 items-center">{item.courier_price || '-'}</div>,
            <InputForm
              id={`approved_by_weight_${item.id}`}
              size="w-32"
              name="approved_by_weight"
              type="checkbox"
              checked={item.approved_by_weight}
              onChange={() => handleCheckboxChange(item, 'approved_by_weight')}
              disabled={!isEdited}
            />,
            <InputForm
              id={`approved_by_price_${item.id}`}
              size="w-32"
              name="approved_by_price"
              type="checkbox"
              checked={item.approved_by_price}
              onChange={() => handleCheckboxChange(item, 'approved_by_price')}
              disabled={!isEdited}
            />,
            <InputForm
              id={`paid_${item.id}`}
              size="w-32"
              name="paid"
              type="checkbox"
              checked={item.paid}
              onChange={() => handleCheckboxChange(item, 'paid')}
              disabled={!isEdited}
            />,
            <InputForm
              id={`active_${item.id}`}
              size="w-32"
              name="active"
              type="checkbox"
              checked={item.active}
              onChange={() => handleCheckboxChange(item, 'active')}
              disabled={!isEdited}
            />
          ]}
        />
      ))}
    </Table>
    <div className="mx-10 flex h-24 place-content-end">
      {itemsCount > 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          itemsCount={itemsCount}
        />
      )}
    </div>
  </>
)

export default TableSection
