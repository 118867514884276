import React, { useState, useCallback } from 'react'


import Pagination from '../../components/shared/Pagination'
import useFetch from '../../hooks/useFetch'
import { TICKETS_STATUS, TICKET_STATES_INDEX } from '../../constants/tickets'
import { getTickets } from '../../helpers/requests/tickets'
import { isSubstring } from '../../utils/strings'
import { sortArrayByKey } from '../../utils/array'
import SectionHeader from '../../components/shared/SectionHeader'
import SearchBar from '../../components/shared/SearchBar'
import ActionsBar from '../../components/shared/Table/ActionsBar'
import TicketsTable from '../../components/Tickets/AllTickets/TicketsTable'

const AllTickets = () => {
  const [tickets, setTickets] = useState([])
  const [searchBarInput, setSearchBarInput] = useState('')
  const [selectedFilters, setSelectedFilters] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [searchResult, setSearchResult] = useState([])

  const setTicketsData = useCallback((data) => {
    const flattenTickets = data.map((ticket) => {
      const item = { ...ticket, ...ticket.package }
      delete item.package
      return item
    })
    flattenTickets.sort((ticketA, ticketB) => ticketB.id - ticketA.id)

    setTickets(flattenTickets)
    setSearchResult(flattenTickets)
  }, [])

  const { isLoading, error } = useFetch(getTickets, setTicketsData)

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const filteredTickets = tickets.filter(
        (ticket) =>
          isSubstring(ticket.id.toString(), searchTerm) ||
          (ticket.pinflagId && isSubstring(ticket.pinflagId, searchTerm)) ||
          (ticket.courier && isSubstring(ticket.courier, searchTerm)) ||
          (ticket.orderId && isSubstring(ticket.orderId, searchTerm)) ||
          (ticket.customerName && isSubstring(ticket.customerName, searchTerm))
      )
      setSearchResult(filteredTickets)
    } else {
      setSearchResult(tickets)
    }
  }

  const handleSort = (sortValues, order) => {
    const sortedCurrentTickets = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedCurrentTickets)
    const sortedTickets = sortArrayByKey(tickets, sortValues, order)

    setTickets(sortedTickets)
  }


  const stateFilteredTickets =
    selectedFilters.length > 0
      ? searchResult.filter((ticket) =>
          selectedFilters.includes(TICKET_STATES_INDEX[ticket.status])
        )
      : searchResult

  const currentTickets = stateFilteredTickets.slice(pageSize * (page - 1), pageSize * page)

  return (
    <div className="flex h-screen flex-col bg-light-grey">
      <SectionHeader
        title="Solicitudes"
        searchBar={
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        }
      />
      <ActionsBar
        statusFilters={TICKETS_STATUS}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
      <TicketsTable
        tickets={currentTickets}
        handleSort={handleSort}
        isLoading={isLoading}
        error={error}
      />
      <div className="mx-10 flex h-24 place-content-end">
        {stateFilteredTickets.length > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            itemsCount={stateFilteredTickets.length}
          />
        )}
      </div>
    </div>
  )
}

export default AllTickets
