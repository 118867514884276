// FormSections.js
import React from 'react'
import InputForm from '../../shared/Input/InputForm'
import FormSection from './FormSection'
import Select from '../../shared/Input/Select'
import { BANK_ACCOUNT_TYPE } from '../../../constants/billing'

export const HoldingProfileSection = ({
  holdingBankData,
  handleHoldingBankDataChange,
  isEditing
}) => (
  <FormSection sectionTitle="Perfil Holding">
    <InputForm
      label="Email Owner"
      id="emailOwner"
      name="ownerEmail"
      type="email"
      value={holdingBankData.ownerEmail}
      onChange={handleHoldingBankDataChange}
      disabled={!isEditing}
    />
    <InputForm
      label="Teléfono Owner"
      id="ownerPhone"
      name="ownerPhone"
      type="tel"
      value={holdingBankData.ownerPhone}
      onChange={handleHoldingBankDataChange}
      disabled={!isEditing}
    />
  </FormSection>
)

export const BillingDataSection = ({
  bankDataState,
  holdingBankData,
  handleBankDataChange,
  handleHoldingBankDataChange,
  financesContact,
  handleContactsChange,
  isEditing
}) => (
  <FormSection sectionTitle="Datos Facturación">
    <InputForm
      label="Nombre razón Social"
      id="socialCause"
      name="socialCause"
      value=""
      disabled={!isEditing}
    />
    <InputForm
      label="Nombre Holding"
      id="holdingName"
      name="name"
      value={bankDataState.name || ''}
      onChange={handleBankDataChange}
      disabled={!isEditing}
    />
    <InputForm
      label="Rut"
      id="ownerSsn"
      name="ownerSsn"
      value={holdingBankData.ownerSsn || ''}
      onChange={handleHoldingBankDataChange}
      disabled={!isEditing}
    />
    <InputForm
      label="Giro"
      id="bankTransfer"
      name="bankTransfer"
      type="text"
      value={holdingBankData.bankTransfer || ''}
      onChange={handleHoldingBankDataChange}
      disabled={!isEditing}
    />
    <InputForm
      label="Contacto Finanzas"
      id="financesWorkerName"
      name="name"
      type="text"
      value={financesContact.name || ''}
      onChange={(event) => handleContactsChange(event, 'finances')}
      disabled={!isEditing}
    />
    <InputForm
      label="Mail Finanzas"
      id="emailFinances"
      name="email"
      type="email"
      value={financesContact.email || ''}
      onChange={(event) => handleContactsChange(event, 'finances')}
      disabled={!isEditing}
    />
    <InputForm
      label="Teléfono Finanzas"
      id="phoneFinances"
      name="phone"
      type="tel"
      value={financesContact.phone || ''}
      onChange={(event) => handleContactsChange(event, 'finances')}
      disabled={!isEditing}
    />
  </FormSection>
)

export const PaymentDataSection = ({
  holdingBankData,
  handleHoldingBankDataChange,
  bankType,
  setBankType,
  isEditing
}) => (
  <FormSection sectionTitle="Datos de Pago">
    <InputForm
      label="Banco"
      id="bankName"
      name="bankName"
      type="text"
      value={holdingBankData.bankName || ''}
      onChange={handleHoldingBankDataChange}
      disabled={!isEditing}
    />
    <Select
      options={BANK_ACCOUNT_TYPE}
      label="Tipo de Cuenta(defaultValue not updated)"
      setter={setBankType}
      withName
      defaultValue={bankType}
      disabled={!isEditing}
    />
    <InputForm
      label="Email"
      id="paymentEmail"
      name="paymentEmail"
      type="email"
      value={holdingBankData.paymentEmail || ''}
      onChange={handleHoldingBankDataChange}
      disabled={!isEditing}
    />
    <InputForm
      label="N Cuenta"
      id="accountNumber"
      name="accountNumber"
      type="text"
      value={holdingBankData.accountNumber || ''}
      onChange={handleHoldingBankDataChange}
      disabled={!isEditing}
    />
  </FormSection>
)
