import React, { useState } from 'react'
import InputForm from '../../shared/Input/InputForm'

const EditBilling = ({ bill }) => {
  const [formValues, setFormValues] = useState({
    folio: bill.folio || '',
    range: bill.range || '',
    emitted: bill.emitted || '',
    expires: bill.expires || '',
    total: bill.total || 0,
    netValue: bill.netValue || '',
    receipt: bill.receipt || '',
    billingPdf: bill.billingPdf || '',
    detail: bill.detail || '',
    nc: bill.nc || []
  })

  const handleChange = (event) => {
    const { name, value, files } = event.target

    setFormValues({
      ...formValues,
      [name]: files || value
    })
  }
  const handleSubmitModal = (event) => {
    event.preventDefault()
  }

  return (
    <form className="" onSubmit={handleSubmitModal}>
      <div className="grid grid-flow-col grid-rows-3">
        <InputForm
          label="Folio"
          id="folio"
          name="folio"
          value={formValues.folio || ''}
          onChange={handleChange}
          properties={{ required: true }}
        />
        <InputForm
          label="Período"
          id="periodoModal"
          name="range"
          value={formValues.range || ''}
          onChange={handleChange}
        />
        <InputForm
          label="Fecha de emisión"
          type="date"
          id="emitModal"
          name="emitted"
          value={formValues.emitted || ''}
          onChange={handleChange}
          properties={{ required: true }}
        />
        <InputForm
          label="Fecha de expiración"
          type="date"
          id="expireDateModal"
          name="expires"
          value={formValues.expires || ''}
          onChange={handleChange}
          properties={{ required: true }}
        />
        <InputForm
          label="Valor sin IVA"
          id="netValueModal"
          name="netValue"
          value={formValues.netValue || ''}
          onChange={handleChange}
        />
        <InputForm
          label="Valor con IVA"
          id="totalModal"
          name="total"
          value={formValues.total || ''}
          onChange={handleChange}
        />
        <InputForm
          label={
            <div>
              <span>PDF Factura</span>
              <span className="ml-2 font-bold">{bill.billingPdf ? 'cambiar' : 'subir'}</span>
            </div>
          }
          type="file"
          id="pdfBillingModal"
          name="pdfBilling"
          onChange={handleChange}
          properties={{ required: true }}
        />
        <InputForm
          label={
            <div>
              <span>Comprobante</span>
              <span className="ml-2 font-bold">{bill.receipt ? 'cambiar' : 'subir'}</span>
            </div>
          }
          type="file"
          id="ncModal"
          name="nc"
          onChange={handleChange}
          properties={{ multiple: true }}
        />
      </div>
      <button
        className="col-start-2 w-24 rounded bg-pinflag-dark py-2 px-4 font-bold text-white hover:bg-light-pinflag hover:text-pinflag-dark"
        type="submit"
      >
        Guardar
      </button>
    </form>
  )
}

export default EditBilling
