import React from 'react'

import Modal from '../shared/Modal/Modal'
import BillingModal from './BillingModal'
import FetchDataModal from './FetchDataModal'

const ModalHandler = ({ showModal, isResponse, modalMessage, loading, handleCloseModal }) => (
  <Modal show={showModal} handleClose={handleCloseModal}>
    {isResponse ? (
      <BillingModal isRequest text={modalMessage} isLoading={loading} />
    ) : (
      <FetchDataModal />
    )}
  </Modal>
)

export default ModalHandler
