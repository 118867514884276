import React, { useEffect, useState } from 'react'
import LogisticsContacts from './LogisticsContacts'
import FormButton from '../../shared/FormButton'
import ConfirmModal from '../../shared/Modal/ConfirmModal'
import Table from '../../shared/Table/Table'
import TableRow from '../../shared/Table/TableRow'
import { setHoldingBankData } from '../../../helpers/requests/companies'
import { BANK_ACCOUNT_TYPE } from '../../../constants/billing'
import { handleBankDataChange, handleContactsChange, handleHoldingBankDataChange, initializeContactState } from '../../../helpers/holdingDetails'
import { BillingDataSection, HoldingProfileSection, PaymentDataSection } from './FormSections'

const HoldingDetails = ({ holding }) => {
  const [holdingBankData, setHoldingBankDataForm] = useState({ ...(holding.holdingBankData || {}) })
  const [bankDataState, setBankDataState] = useState({ ...(holding.bankData || {}) })
  const [distributionCenter, setDistributiionCenter] = useState({
    ...(holding.distributionCenter || {})
  })
  const [financesContact, setFinancesContact] = useState({})
  const [logisticsContacts, setLogisticsContacts] = useState([{}])
  const [isEditing, setIsEditing] = useState(false)
  const [initialState, setInitialState] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenConfirm, setOpenConfirm] = useState(false)
  const [bankType, setBankType] = useState(
    holding.holdingBankData?.accountType || BANK_ACCOUNT_TYPE[0].name
  )

  const { contact = [], holdingId } = holding

  useEffect(() => {
    initializeContactState(contact, setLogisticsContacts, setFinancesContact)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    holdingBankData.accountType = bankType
  }, [bankType, holdingBankData])

  const handleEdit = () => {
    setInitialState({
      holdingBankData: { ...(holding.holdingBankData || {}) },
      bankData: { ...(holding.bankData || {}) },
      financesContact,
      logisticsContacts,
      distributionCenter
    })
    setIsEditing(true)
  }

  const addLogisticsContact = () => {
    setLogisticsContacts((prevContacts) => [
      ...prevContacts,
      { phone: '', email: '', type: 'logistics' }
    ])
  }

  const onSubmit = async () => {
    setIsLoading(true)
    await setHoldingBankData(holdingId, {
      bankData: bankDataState,
      holdingBankData,
      contact: [financesContact, logisticsContacts],
      distributionCenter
    })
    setIsEditing(false)
    setIsLoading(false)
  }

  const resetForm = () => {
    setHoldingBankDataForm({ ...initialState?.holdingBankData })
    setBankDataState({ ...initialState?.bankData })
    setFinancesContact({ ...initialState?.financesContact })
    setLogisticsContacts([...(initialState?.logisticsContacts || [])])
    setDistributiionCenter({ ...initialState?.distributionCenter })
    setIsEditing(false)
  }

  return (
    <form className="mx-10 mt-5 grid grid-cols-2  text-left text-sm font-medium text-gray-900">
      <div className="grid grid-flow-row auto-rows-min gap-4">
        <HoldingProfileSection
          holdingBankData={holdingBankData}
          handleHoldingBankDataChange={(e) =>
            handleHoldingBankDataChange(e, setHoldingBankDataForm)
          }
          isEditing={isEditing}
        />
        <BillingDataSection
          bankDataState={bankDataState}
          holdingBankData={holdingBankData}
          handleBankDataChange={(e) => handleBankDataChange(e, setBankDataState)}
          handleHoldingBankDataChange={(e) =>
            handleHoldingBankDataChange(e, setHoldingBankDataForm)
          }
          financesContact={financesContact}
          handleContactsChange={(e, type, index) =>
            handleContactsChange(e, type, index, setFinancesContact, setLogisticsContacts)
          }
          isEditing={isEditing}
        />
        <PaymentDataSection
          holdingBankData={holdingBankData}
          handleHoldingBankDataChange={(e) =>
            handleHoldingBankDataChange(e, setHoldingBankDataForm)
          }
          bankType={bankType}
          setBankType={setBankType}
          isEditing={isEditing}
        />
      </div>
      <div className="grid grid-flow-row auto-rows-max gap-5">
        <div className=" ">
          <div className="col-span-2 mb-2 text-xl font-medium text-ultra-dark-grey">Empresas</div>
          <Table headers={['Empresa', 'Contacto', 'Tipo de Empresa']}>
            {holding.bankData?.Empresas?.map((company) => (
              <TableRow
                key={company.nombreempresa}
                items={[
                  <div className="flex items-center">{company.nombreempresa}</div>,
                  <div className="flex items-center">{company.contact_email}</div>,
                  <div className="flex items-center">{company.company_type}</div>
                ]}
              />
            ))}
          </Table>
        </div>
        <LogisticsContacts
          logisticsContacts={logisticsContacts}
          addLogisticsContact={addLogisticsContact}
          handleContactsChange={(e, type, index) =>
            handleContactsChange(e, type, index, setFinancesContact, setLogisticsContacts)
          }
          isEditing={isEditing}
        />
      </div>
      <div className="col-start-2 flex content-start justify-end gap-2">
        <FormButton
          isEditing={isEditing}
          onClick={() => (isEditing ? resetForm() : handleEdit())}
          text={isEditing ? 'Cancelar' : 'Editar'}
          styles="py-2 px-4"
        />
        <FormButton
          isEditing={!isEditing}
          disabled={!isEditing}
          text="Guardar"
          styles="py-2 px-4"
          onClick={() => setOpenConfirm(true)}
        />
      </div>
      <ConfirmModal
        handleSubmit={onSubmit}
        show={isOpenConfirm}
        handleClose={() => setOpenConfirm(false)}
        isLoading={isLoading}
      />
    </form>
  )
}

export default HoldingDetails
