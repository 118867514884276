export const SERVICES_TYPES = [
  'standard',
  'express',
  'same-day'
]

export const COURIER_PLAN_NAMES = [
  'pyme',
  'medium',
  'large',
  'extralarge'
]

export const SERVICES_TYPES_LIST = [
  { id: 'standard', name: 'Standard' },
  { id: 'express', name: 'Express' },
  { id: 'same-day', name: 'Same Day' },
  { id: 'ship-from-store', name: 'Ship From Store' }
]

export const COURIERS = [
  { id: 1, name: 'chilexpress' },
  { id: 2, name: 'blueexpress' },
  { id: 3, name: 'shippify' },
  { id: 4, name: 'urbano' },
  { id: 5, name: 'recibelo' }
]
